
// create a header component
import { useState } from "react";
import InquiryModal from "./InquiryModal";

const TechBackground = () => (
    <div className="absolute inset-0 overflow-hidden">
      <svg 
        className="absolute w-full h-full opacity-20"
        viewBox="0 0 100 100" 
        preserveAspectRatio="xMidYMid slice"
      >
        {/* Animated Circuit Lines */}
        <path className="animate-circuit1" d="M0,50 Q25,25 50,50 T100,50" 
          fill="none" stroke="red" strokeWidth="0.2"/>
        <path className="animate-circuit2" d="M0,30 Q35,45 50,30 T100,30" 
          fill="none" stroke="red" strokeWidth="0.2"/>
        <path className="animate-circuit3" d="M0,70 Q15,55 50,70 T100,70" 
          fill="none" stroke="red" strokeWidth="0.2"/>
        
        {/* Hexagon Grid */}
        {[...Array(10)].map((_, i) => (
          [...Array(10)].map((_, j) => (
            <path
              key={`hex-${i}-${j}`}
              d="M0,-4 L3.5,-2 L3.5,2 L0,4 L-3.5,2 L-3.5,-2 Z"
              className="animate-hexagon"
              style={{
                transform: `translate(${i * 10 + (j % 2) * 5}px, ${j * 8}px)`,
                animationDelay: `${(i + j) * 0.1}s`
              }}
              fill="none"
              stroke="red"
              strokeWidth="0.1"
            />
          ))
        ))}
        
        {/* Animated Dots */}
        {[...Array(20)].map((_, i) => (
          <circle
            key={`dot-${i}`}
            className="animate-dot"
            cx={Math.random() * 100}
            cy={Math.random() * 100}
            r="0.5"
            fill="red"
            style={{
              animationDelay: `${Math.random() * 3}s`
            }}
          />
        ))}
      </svg>
    </div>
  );

  // Add these styles to your CSS
const styles = `
@keyframes circuit {
  0% { stroke-dashoffset: 100; }
  100% { stroke-dashoffset: -100; }
}

@keyframes hexagon {
  0% { opacity: 0; transform: scale(0.8); }
  50% { opacity: 0.5; transform: scale(1.1); }
  100% { opacity: 0; transform: scale(0.8); }
}

@keyframes dot {
  0% { transform: scale(0); opacity: 0; }
  50% { transform: scale(1.5); opacity: 1; }
  100% { transform: scale(0); opacity: 0; }
}

.animate-circuit1 {
  stroke-dasharray: 100;
  animation: circuit 10s linear infinite;
}

.animate-circuit2 {
  stroke-dasharray: 100;
  animation: circuit 15s linear infinite;
}

.animate-circuit3 {
  stroke-dasharray: 100;
  animation: circuit 12s linear infinite;
}

.animate-hexagon {
  animation: hexagon 4s ease-in-out infinite;
}

.animate-dot {
  animation: dot 3s ease-in-out infinite;
}
`;


const Header = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
      <div className="bg-slate-900 text-white relative overflow-hidden">
      <style>{styles}</style>
      <TechBackground />
      <div className="container mx-auto px-4 py-16 md:py-24 relative z-10">
        <div className="max-w-3xl">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            Transform Your Construction Projects with Custom Software Solutions
          </h1>
          <p className="text-xl mb-8">
            Streamline project management, enhance collaboration, and optimize resource utilization with our tailored software solutions.
          </p>
          <button 
            onClick={() => setIsModalOpen(true)}
            className="bg-yellow-500 text-blue-900 px-8 py-3 rounded-lg font-semibold text-lg hover:bg-yellow-400 transition-colors"
          >
            Get A Qoute
          </button>
        </div>
      </div>
      <InquiryModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
        );
    };

export default Header;

