import React, { useState } from 'react';
import { Building2, Users, Clock, Wrench, Brain, Code, X } from 'lucide-react';
import Header from './common/Header';
import Footer from './common/Footer';


const Landing = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const benefits = [
    {
      icon: <Building2 className="h-8 w-8" />,
      title: "Custom-Built Solutions",
      description: "Tailored software designed specifically for construction companies"
    },
    {
      icon: <Users className="h-8 w-8" />,
      title: "Enhanced Collaboration",
      description: "Real-time updates and centralized information sharing"
    },
    {
      icon: <Clock className="h-8 w-8" />,
      title: "Time & Resource Optimization",
      description: "Efficient project tracking and resource management"
    }
  ];

  const features = [
    {
      icon: <Wrench className="h-6 w-6" />,
      title: "18+ Years Experience",
      description: "Deep expertise in developing robust construction software"
    },
    {
      icon: <Brain className="h-6 w-6" />,
      title: "AI Integration",
      description: "Cutting-edge AI technology for smarter project management"
    },
    {
      icon: <Code className="h-6 w-6" />,
      title: "Modern Tech Stack",
      description: "Built with ReactJS and Node.js for maximum scalability"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />

      {/* Benefits Section */}
      <div className="py-16 container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Key Benefits</h2>
        <div className="grid md:grid-cols-3 gap-8">
          {benefits.map((benefit, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg">
              <a href="/Benefits">
              <div className="text-slate-900 mb-4">{benefit.icon}</div>
              <h3 className="text-xl font-semibold mb-2">{benefit.title}</h3>
              <p className="text-gray-600">{benefit.description}</p>
              </a>
            </div>
          ))}
        </div>
      </div>

      {/* Experience Section */}
      <div className="bg-gray-100 py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">Why Choose Us</h2>
          <div className="max-w-2xl mx-auto">
            <div className="space-y-8">
              {features.map((feature, index) => (
                <div 
                  key={index} 
                  className="flex items-start bg-white p-6 rounded-lg shadow-md"
                >
                  <div className="bg-slate-900 p-3 rounded-full text-white mr-4">
                    {feature.icon}
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                    <p className="text-gray-600">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Landing;