import { useState } from "react";
import { X } from "lucide-react";

const EmailForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    description: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ type: "", message: "" });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setSubmitStatus({ type: "", message: "" });

    const author = {
      type: "TOKEN",
      authorizationToken: "saasaan4",
      methodArn:
        "arn:aws:execute-api:ap-northeast-1:139345353283:lvc4brs2pb/*/POST/email",
    };

    try {
      const response = await fetch(
        "https://lvc4brs2pb.execute-api.ap-northeast-1.amazonaws.com/stg/email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${author.authorizationToken}`,
          },
          body: JSON.stringify({
            user: "email signup User",
            phone: "090000121",
            email: formData.email,
            projectName: "Email Signup for 7 List Traffic tools",
            projectDescription: "this is a traffic tools list to send",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to submit inquiry");
      }

      setSubmitStatus({
        type: "success",
        message: "Thank you for your inquiry! We will contact you soon.",
      });

      // Clear form after successful submission
      setFormData({
        name: "",
        phone: "",
        email: "",
        description: "",
      });

      // Close modal after 2 seconds on success
      setTimeout(() => {
        setSubmitStatus({ type: "", message: "" });
      }, 2000);
    } catch (error) {
      console.error("Submission error:", error);
      setSubmitStatus({
        type: "error",
        message: " Failed . Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-md relative">
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-6">Enter Your Email</h2>

          {submitStatus.message && (
            <div
              className={`mb-4 p-3 rounded ${
                submitStatus.type === "success"
                  ? "bg-green-100 text-green-700"
                  : "bg-red-100 text-red-700"
              }`}
            >
              {submitStatus.message}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                required
                disabled={isLoading}
              />
            </div>

            <div className="flex justify-end space-x-3 mt-6">
              <button
                type="submit"
                className="px-4 py-2 bg-slate-900 text-white rounded-md hover:bg-blue-800 disabled:opacity-50 flex items-center"
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <svg
                      className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="Black"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Submitting...
                  </>
                ) : (
                  "Send Me The List of Tools"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EmailForm;
