import EmailForm from "./common/EmailFormSignup";

const Emailsignup = () => {
  return (
    <div>
      <article className="p-6 max-w-4xl mx-auto bg-white shadow-md rounded-lg">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          TO GET 7 LIST OF TOOLS TO GET MORE TRAFFIC
        </h2>
        <p className="text-gray-700 mb-6">
          Enter Your Email: We Will Send You The List Via Email
        </p>

        <p className="text-gray-700 mt-6">
          <EmailForm />
        </p>
      </article>
    </div>
  );
};

export default Emailsignup;
