import { createBrowserRouter, Link } from "react-router-dom";

import Landing from "../components/Landing";
import Benefits from "../components/Benefits";
import Emailsignup from "../components/Emailsignup";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "contact",
    element: <div>Contact</div>,
  },
  {
    path: "Benefits",
    element: <Benefits />,
  },
  {
    path: "about",
    element: <div>About</div>,
  },
  {
    path: "emailsignup",
    element: <Emailsignup />,
  },
]);

export default Router;
