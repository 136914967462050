// create a Benefits component
import Header from "./common/Header";
import Footer from "./common/Footer";

const Benefits = () => {
    return (
        <div>
            <Header />
        <article className="p-6 max-w-4xl mx-auto bg-white shadow-md rounded-lg">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          A Tailored System for Construction Business: Key Benefits
        </h2>
        <p className="text-gray-700 mb-6">
          A tailored system for a construction business can be transformative, offering several significant benefits that improve efficiency, productivity, and project profitability. Here are some key advantages:
        </p>
  
        <ol className="space-y-6 list-decimal list-inside text-gray-800">
          <li>
            <h3 className="text-xl font-semibold text-blue-600">
              Enhanced Project Management and Tracking
            </h3>
            <p className="text-gray-700">
              Custom systems enable detailed tracking of project milestones, resource allocation, and timelines, which is especially useful for construction projects with multiple phases. This leads to better control over schedules and reduces delays, which are common in construction.
            </p>
          </li>
  
          <li>
            <h3 className="text-xl font-semibold text-blue-600">
              Optimized Resource Allocation
            </h3>
            <p className="text-gray-700">
              Tailored solutions allow for precise allocation of labor, equipment, and materials to specific projects and tasks. This helps avoid resource wastage and prevents downtime. Managers can quickly assess resource availability and make adjustments, leading to cost savings and increased productivity.
            </p>
          </li>
  
          <li>
            <h3 className="text-xl font-semibold text-blue-600">
              Improved Cost Estimation and Budgeting
            </h3>
            <p className="text-gray-700">
              Custom software allows for more accurate cost estimation and budget tracking, factoring in specific variables relevant to the business. This leads to more accurate bidding and better financial control, helping construction firms avoid budget overruns.
            </p>
          </li>
  
          <li>
            <h3 className="text-xl font-semibold text-blue-600">
              Real-Time Data and Reporting
            </h3>
            <p className="text-gray-700">
              Tailored systems can integrate with on-site data collection tools to provide real-time insights into project progress, safety compliance, and financials. This real-time visibility enables proactive decision-making and helps identify and resolve issues early, reducing costly delays.
            </p>
          </li>
  
          <li>
            <h3 className="text-xl font-semibold text-blue-600">
              Streamlined Communication and Collaboration
            </h3>
            <p className="text-gray-700">
              Customized systems can include project collaboration features that facilitate communication between teams on-site and those in the office. Having a unified communication channel improves teamwork and reduces misunderstandings, making complex projects run more smoothly.
            </p>
          </li>
  
          <li>
            <h3 className="text-xl font-semibold text-blue-600">
              Better Compliance and Safety Management
            </h3>
            <p className="text-gray-700">
              Custom solutions can be designed to track and document compliance with industry regulations and safety standards, which is critical in construction. This helps to reduce the risk of violations, costly fines, and worker injuries, and contributes to a safer working environment.
            </p>
          </li>
  
          <li>
            <h3 className="text-xl font-semibold text-blue-600">
              Integration with Existing Tools and Workflows
            </h3>
            <p className="text-gray-700">
              A tailored system can seamlessly integrate with other tools (like CAD software, inventory systems, or financial platforms) that a construction company already uses. This minimizes workflow disruptions and ensures that data is consistent and accessible across different applications.
            </p>
          </li>
  
          <li>
            <h3 className="text-xl font-semibold text-blue-600">
              Scalability for Future Growth
            </h3>
            <p className="text-gray-700">
              As construction businesses grow, they often need more sophisticated tools. Custom systems can be designed to scale up as the business expands or as new processes are added. This avoids the need for costly software changes or overhauls and allows the system to grow with the business.
            </p>
          </li>
        </ol>
  
        <p className="text-gray-700 mt-6">
          Investing in a tailored system not only streamlines daily operations but also adds significant strategic value by improving profitability, minimizing risks, and setting the foundation for scalable growth.
        </p>
      </article>
      <Footer />
      </div>
          );
        };


export default Benefits;
