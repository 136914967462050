import './index.css';

import React from 'react';
import TagManager from 'react-gtm-module';
import {
  RouterProvider,
} from "react-router-dom";
import Router from './routes/router';


// Initialize GTM with your ID
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID, // Replace with your GTM ID
  // Optional configurations
  //auth: 'XXXXXXXXXXXXX',  //Optional authentication hash
  // preview: 'env-1', // Optional environment for testing
  dataLayer: {
    // Any data you want to initialize with
    page: window.location.pathname,
    // Add any other dataLayer variables you need
  }
};



function App() {
  React.useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);
  return (
    <RouterProvider router={Router} />
  );
}

export default App;
