// create footer component
import { useState } from "react";
import InquiryModal from "./InquiryModal";


const Footer = ()  => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
    <div className="bg-slate-900 text-white py-16">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-3xl font-bold mb-6">Ready to Optimize Your Construction Projects?</h2>
        <p className="text-xl mb-8">
          Join the growing number of construction companies transforming their operations with our custom software solutions.
        </p>
        <div className="space-x-4">
          <button 
            onClick={() => setIsModalOpen(true)}
            className="bg-yellow-500 text-blue-900 px-8 py-3 rounded-lg font-semibold text-lg hover:bg-yellow-400 transition-colors"
          >
            Get Started
          </button>
          <button
            onClick={()=>{
                window.location.href = "/Benefits";
            } } 
            className="border-2 border-white px-8 py-3 rounded-lg font-semibold text-lg hover:bg-white hover:text-blue-900 transition-colors">
            Learn More
          </button> 
        </div>
      </div>
      <InquiryModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
    );
};

export default Footer;